import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

const AboutButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className={styles.RouteButton}
      onClick={() => {
        navigate('/about');
      }}
    >
      About
    </button>
  );
};

export default AboutButton;
