import styles from './styles.module.css';
import React from 'react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { desktopImages, mobileImages } from '../utils/imagesArr';

register();

const ImageSlider: React.FC = () => {
  const { width: screenWidth } = useWindowSize();

  const data = screenWidth > 745 ? desktopImages : mobileImages;

  return (
    <div className={styles.SlideContainer}>
      <Swiper
        speed={900}
        autoplay
        loop
        slidesPerView={'auto'}
        centeredSlides
        spaceBetween={20}
        pagination={{
          el: '.swiper-custom-pagination',
          clickable: true,
        }}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
          disabledClass: 'swiper-button-disabled',
        }}
      >
        {data.map((item) => (
          <SwiperSlide
            key={item.id}
            style={{ width: 'fit-content', cursor: 'grab' }}
          >
            <div className={styles.SlideWrapper}>
              <img
                className={styles.SlideImage}
                src={item.image}
                alt='Slider'
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
