'use client';
import styles from './styles.module.css';
import {
  useState,
  useEffect,
  RefObject,
  SetStateAction,
  Dispatch,
} from 'react';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';

register();

interface IImageSliderProps {
  PlayOrPauseVideo: (index: number) => void;
  pauseAllVideos: () => void;
  currentSlideIndex: number;
  setCurrentSlideIndex: Dispatch<SetStateAction<number>>;
  videos: {
    title: string;
    description: string;
    index: number;
    source: string;
    isPlaying: boolean;
    ref: RefObject<HTMLVideoElement>;
  }[];
}

const ImageSlider = ({
  videos,
  currentSlideIndex,
  setCurrentSlideIndex,
  PlayOrPauseVideo,
  pauseAllVideos,
}: IImageSliderProps) => {
  const [swiper, setSwiper] = useState<any | null>(null);

  useEffect(() => {
    videos.forEach((video) => {
      const videoElement = video.ref.current;
      if (video.isPlaying) {
        videoElement?.play();
      } else {
        videoElement?.pause();
      }

      videoElement?.addEventListener('ended', () => {
        const nextIndex = (video.index + 1) % videos.length;
        setCurrentSlideIndex(nextIndex);
        swiper?.slideTo(nextIndex);
      });
    });
  }, [videos, swiper]);

  useEffect(() => {
    swiper?.slideTo(currentSlideIndex);
  }, [currentSlideIndex, swiper]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const videoElement = entry.target as HTMLVideoElement;
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    videos.forEach((video) => {
      if (video.ref.current) {
        observer.observe(video.ref.current);
      }
    });

    return () => {
      videos.forEach((video) => {
        if (video.ref.current) {
          observer.unobserve(video.ref.current);
        }
      });
    };
  }, [videos]);

  return (
    <div className={styles.SlideContainer}>
      <Swiper
        onSwiper={setSwiper}
        onActiveIndexChange={(swiper: any) => {
          setCurrentSlideIndex(swiper.activeIndex);
          pauseAllVideos();
        }}
        slidesPerView={1}
        centeredSlides
        spaceBetween={20}
        pagination={{
          el: '.swiper-video-custom-pagination',
          clickable: true,
        }}
      >
        {videos.map((item) => (
          <SwiperSlide
            key={item.index}
            style={{ width: 'fit-content', cursor: 'grab' }}
          >
            <div className={styles.SlideWrapper}>
              <video
                controls
                muted
                src={item.source}
                className={styles.SlideVideo}
                ref={item.ref}
                playsInline
                webkit-playsinline='true'
                onClick={() => {
                  PlayOrPauseVideo(item.index);
                }}
              ></video>
              <div className={styles.VideoInformationWrapper}>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
