import { useState } from 'react';
import styles from './styles.module.css';
import { MotionDiv } from '../MotionDiv';
import Cookies from 'js-cookie';

interface ILoginData {
  type: 'Error' | 'Connected';
  id: number;
  file: string;
  name: string;
  email: string;
  password: string;
}

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    setEmailError(false);
    setPasswordError(false);

    if (email === '' || password === '' || !isValidEmail(email)) {
      if (email === '') setEmailError(true);
      if (password === '') setPasswordError(true);
      if (email !== '' && !isValidEmail(email)) {
        setEmailError(true);
        setError('Please enter a valid email address.');
      } else {
        setError('Please fill in all fields.');
      }
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('type_post', 'logar');
    formData.append('type', 'logar');
    formData.append('email', email);
    formData.append('password', password);
    formData.append('name_project', '/');
    formData.append('link_project', 'https://dash.go-bees.com/');

    try {
      const response = await fetch(
        'https://dash.go-bees.com/php/inc/login_site.php',
        {
          method: 'POST',
          body: formData,
        }
      );

      const data: ILoginData = await response.json();

      // Adicione o console.log aqui para visualizar os dados retornados pela API
      console.log('Login Data:', data);

      if (data.type === 'Error') {
        setError(
          'Failed to login. Please check your credentials and try again.'
        );
        setLoading(false);

        return;
      }

      if (data.type === 'Connected') {
        const day = new Date();
        const add3Days = new Date();
        add3Days.setDate(day.getDate() + 3);

        Cookies.remove('dash_bees', { domain: '.go-bees.com', path: '/' });
        Cookies.remove('dash_bees_passw', {
          domain: '.go-bees.com',
          path: '/',
        });
        Cookies.remove('dash_bees_email', {
          domain: '.go-bees.com',
          path: '/',
        });
        Cookies.remove('dash_bees_page', { domain: '.go-bees.com', path: '/' });

        Cookies.set('dash_bees', 'dashboard', {
          domain: '.go-bees.com',
          path: '/',
          secure: true,
        });
        Cookies.set('dash_bees_passw', data.password, {
          domain: '.go-bees.com',
          path: '/',
          secure: true,
        });
        Cookies.set('dash_bees_email', email, {
          domain: '.go-bees.com',
          path: '/',
          secure: true,
        });
        Cookies.set('dash_bees_page', 'index', {
          domain: '.go-bees.com',
          path: '/',
          secure: true,
        });

        window.location.href = 'https://dash.go-bees.com/';
      }
    } finally {
      return;
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (value !== '' && isValidEmail(value)) {
      setEmailError(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (value !== '') {
      setPasswordError(false);
    }
  };

  return (
    <>
      <MotionDiv
        className={styles.FormDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <input
          style={{
            borderBottom: '1px solid #e7e7e7',
            background: emailError ? '#ff000012' : 'none',
          }}
          type='text'
          name='email'
          placeholder='Enter your email'
          className={styles.Input}
          value={email}
          onChange={handleEmailChange}
        />
        <div style={{ position: 'relative' }}>
          <input
            style={{
              background: passwordError ? '#ff000012' : 'none',
            }}
            type={passwordVisible ? 'text' : 'password'}
            name='password'
            placeholder='Password'
            className={styles.Input}
            value={password}
            onChange={handlePasswordChange}
          />
          <button
            type='button'
            onClick={togglePasswordVisibility}
            className={styles.ViewPasswordButton}
          >
            <img
              src={
                passwordVisible
                  ? '/assets/icons/eye.svg'
                  : '/assets/icons/eye_slash.svg'
              }
              alt={'viewPassword'}
              width={22}
              height={22}
            />
          </button>
        </div>
        <button
          className={styles.LoginButton}
          onClick={() => {
            if (!loading) {
              handleLogin();
            }
          }}
          disabled={loading}
          style={{ cursor: loading ? 'default' : 'pointer' }}
        >
          {loading ? <p>Loading...</p> : <p>LOGIN</p>}
          {!loading && (
            <img
              src={'/assets/icons/login.svg'}
              alt={'login'}
              width={20}
              height={20}
            />
          )}
        </button>
      </MotionDiv>
      {error && <p className={styles.ErrorMessage}>{error}</p>}
      <div className={styles.LoginFooter}>
        <div className={styles.RememberUserDiv}>
          <input
            className={styles.CustomCheckbox}
            type='checkbox'
            name='contactMethodPhone'
          />
          <button style={{ cursor: 'default' }}>Remember me</button>
        </div>
        <div>
          <button className={styles.ForgotPasswordButton}>
            Forgot password?
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
