export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#ff000000',
    color: '#ffffff',
    borderRadius: '8px',
    height: '56px',
    border: '1px solid #ffffff',
    boxShadow: state.isFocused ? '0px 0px 6px #ffffffad' : 'none',
    '&:hover': {
      border: '1px solid #ffffff',
      boxShadow: '0px 0px 6px #ffffffad',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#ffffff',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#000000',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#333333' : '#000000',
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 400,

    '&:hover': {
      backgroundColor: '#333333',
    },
  }),
};

export const customErrorStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#ff000000',
    color: '#ff8c8c',
    borderRadius: '8px',
    height: '56px',
    border: '1px solid #ff8c8c',
    boxShadow: state.isFocused ? '0px 0px 6px #ffb9b9ad' : 'none',
    '&:hover': {
      border: '1px solid #ff8c8c',
      boxShadow: '0px 0px 6px #ffb9b9ad',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#ff8c8c',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#000000',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#333333' : '#000000',
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 400,

    '&:hover': {
      backgroundColor: '#333333',
    },
  }),
};
