import { useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
  ContactDiv,
  ContactEmailDiv,
  Container,
  ContentWrapper,
  ContentButton,
  ContentContainer,
  ContentList,
  TitleDiv,
  ContentDiv,
  TitleTextsDiv,
} from './styles';
import { MotionDiv } from '../../components/MotionDiv';
import {
  ContentTexts1,
  ContentTexts10,
  ContentTexts2,
  ContentTexts3,
  ContentTexts4,
  ContentTexts5,
  ContentTexts6,
  ContentTexts7,
  ContentTexts8,
  ContentTexts9,
} from './components/ContentTexts';
import useWindowSize from '../../hooks/useWindowSize';

const PrivacyPolicy = () => {
  const { width: screenWidth } = useWindowSize();
  const [visibleContentIndices, setVisibleContentIndices] = useState<number[]>(
    []
  );

  const toggleContent = (index: number) => {
    if (visibleContentIndices.includes(index)) {
      setVisibleContentIndices(
        visibleContentIndices.filter((i) => i !== index)
      );
    } else {
      setVisibleContentIndices([...visibleContentIndices, index]);
    }
  };

  return (
    <>
      <Container id='scrollableDiv'>
        <Header />
        <ContentContainer>
          <TitleDiv>
            <MotionDiv
              style={{ width: 56 }}
              initial={{ opacity: 0, y: 300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0 }}
            >
              <img src='/assets/icons/bees_b_logo.svg' alt='b' />
            </MotionDiv>
            <MotionDiv
              initial={{ opacity: 0, y: 300 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              <h1>Privacy Policy</h1>
            </MotionDiv>

            <MotionDiv
              initial={{ opacity: 0, x: 600 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 1.3 }}
            >
              <TitleTextsDiv>
                <p>
                  Please read these Terms of Use (the “Terms”) carefully as they
                  govern your use (which includes access) of BEES' personalized
                  real estate services, including all of our websites and
                  software applications that embed or link to to these Terms
                  (collectively, the “BEES Service”) and any content, such as
                  images, videos, floor plans, 3D tours, solar studies and other
                  real estate-related materials, that are available through the
                  BEES Service (the “Content”).
                </p>
                <p>
                  Use of the BEES Service may be subject to additional terms and
                  conditions presented by BEES, which are incorporated herein by
                  reference into these Terms.
                </p>
                <p>
                  By signing up for or otherwise using the BEES Service, you
                  agree to these Terms. If you do not agree to these Terms, then
                  you must not use the BEES Service or access any Content.
                </p>
              </TitleTextsDiv>
            </MotionDiv>
          </TitleDiv>
          <MotionDiv
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1.8 }}
            style={{ width: screenWidth <= 750 ? '100%' : 'unset' }}
          >
            <ContentList>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(0)}>
                  <h2>1. Service Provider</h2>
                  <img
                    src={
                      visibleContentIndices.includes(0)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(0) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts1 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(1)}>
                  <h2>2. The BEES Service Provided by Us</h2>
                  <img
                    src={
                      visibleContentIndices.includes(1)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(1) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts2 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(2)}>
                  <h2>3. Personal Data We Collect About You</h2>
                  <img
                    src={
                      visibleContentIndices.includes(2)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(2) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts3 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(3)}>
                  <h2>4. Our Purpose For Using Your Personal Data</h2>
                  <img
                    src={
                      visibleContentIndices.includes(3)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(3) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts4 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(4)}>
                  <h2>5. Sharing Your Personal Data</h2>
                  <img
                    src={
                      visibleContentIndices.includes(4)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(4) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts5 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(5)}>
                  <h2>6. Data Retention</h2>
                  <img
                    src={
                      visibleContentIndices.includes(5)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(5) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts6 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(6)}>
                  <h2>7. Transfer To Other Countries</h2>
                  <img
                    src={
                      visibleContentIndices.includes(6)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(6) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts7 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(7)}>
                  <h2>8. Keeping Your Personal Data Safe</h2>
                  <img
                    src={
                      visibleContentIndices.includes(7)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(7) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts8 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(8)}>
                  <h2>9. Children</h2>
                  <img
                    src={
                      visibleContentIndices.includes(8)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(8) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts9 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
              <ContentWrapper>
                <ContentButton onClick={() => toggleContent(9)}>
                  <h2>10. Changes To This Policy</h2>
                  <img
                    src={
                      visibleContentIndices.includes(9)
                        ? '/assets/icons/minus_cirlce.svg'
                        : '/assets/icons/add_circle.svg'
                    }
                    alt='show'
                  />
                </ContentButton>
                {visibleContentIndices.includes(9) && (
                  <MotionDiv
                    initial={{ opacity: 0, y: -16 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    <ContentDiv>
                      <ContentTexts10 />
                    </ContentDiv>
                  </MotionDiv>
                )}
              </ContentWrapper>
            </ContentList>
          </MotionDiv>

          <MotionDiv
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1.8 }}
          >
            <ContactDiv>
              <p>
                For any questions or concerns about this Policy, please contact
                our Data Protection Officer:
              </p>
              <ContactEmailDiv>
                <img src='/assets/icons/email_icon.svg' alt='email' />
                <p>info@go-bees.com</p>
              </ContactEmailDiv>
              <p>
                BEES is the data controller of personal data processed under
                this Policy.
              </p>
              <p>© BEES.</p>
            </ContactDiv>
          </MotionDiv>
        </ContentContainer>
        <Footer paddingTop={150} />
      </Container>
    </>
  );
};

export default PrivacyPolicy;
