import styles from './styles.module.css';
import DashboardSection from './sections/DashboardSection';
import DeviceExperienceSection from './sections/DeviceExperienceSection';
import FooterSection from './sections/FooterSection';
import FormSection from './sections/FormSection';
import InitialSection from './sections/InitialSection';
import ImageGallerySection from './sections/ImageGallerySection';
import DesktopVideoGallerySection from './sections/DesktopVideoGallerySection';
import useWindowSize from '../../hooks/useWindowSize';
import MobileVideoGallerySection from './sections/MobileVideoGallerySection';

export default function Home() {
  const { width: screenWidth } = useWindowSize();

  return (
    <main>
      <div id='scrollableDiv' className={styles.AllContentWrapper}>
        <InitialSection />
        {screenWidth > 860 && <DesktopVideoGallerySection />}
        <DeviceExperienceSection />
        {screenWidth <= 860 && <MobileVideoGallerySection />}
        <ImageGallerySection />
        <DashboardSection />
        <FormSection />
        <FooterSection />
      </div>
    </main>
  );
}
