import styles from './styles.module.css';
import { useState } from 'react';
import LoginModal from '../LoginModal';
import { MotionButton } from '../MotionDiv';

const UserProfile = () => {
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      <div className={styles.UserButtonDiv}>
        <MotionButton
          className={styles.UserProfileButton}
          onClick={() => {
            setShowProfileOptions((prevState) => !prevState);
          }}
          whileHover={{ scale: 1.1 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <img
            src={'/assets/icons/profile.svg'}
            alt={'profile'}
            width={24}
            height={24}
          />
        </MotionButton>
        <button
          className={styles.OpenProfileOptionsButton}
          onClick={() => {
            setShowProfileOptions((prevState) => !prevState);
          }}
        >
          <img
            src={'/assets/icons/arrow_up.svg'}
            alt={'profile'}
            width={8}
            height={8}
            style={{
              transform: showProfileOptions ? 'rotate(180deg)' : 'unset',
            }}
          />
        </button>
        {showProfileOptions && (
          <div className={styles.ProfileOptionsDiv}>
            <div>
              <button
                onClick={() => {
                  setShowLoginModal(true);
                }}
              >
                Login
              </button>
            </div>
          </div>
        )}
      </div>
      {showLoginModal && <LoginModal closeLoginModal={closeLoginModal} />}
    </>
  );
};

export default UserProfile;
