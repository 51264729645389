import styles from './styles.module.css';
import { MotionButton } from '../MotionDiv';
import { scrollToScheduleDemo } from '../../functions/scrollToDiv';

const ScheduleDemoButton = () => {
  return (
    <MotionButton
      className={styles.ScheduleDemoButton}
      whileHover={{ scale: 1.1 }}
      transition={{ type: 'spring', stiffness: 300 }}
      onClick={scrollToScheduleDemo}
    >
      <p className={styles.DemoButtonText}>Schedule a demo now</p>
      <img
        src='/assets/icons/right_arrow_icon.svg'
        alt={'arrow'}
        height={16}
        width={16}
      />
    </MotionButton>
  );
};

export default ScheduleDemoButton;
