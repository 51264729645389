import Header from '../../../../components/Header';
import { MotionDiv, MotionH1 } from '../../../../components/MotionDiv';
import { ContainerWrapper } from './styles';
import styles from './styles.module.css';

const InitialSection = () => {
  return (
    <ContainerWrapper>
      <div className={styles.Container}>
        <Header />
        <div className={styles.CenterTitleDiv}>
          <MotionH1
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            Welcome to The Real Estate Platform
          </MotionH1>
        </div>
        <div className={styles.ScrollDownDiv}>
          <MotionDiv
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <MotionDiv
              animate={{ y: [0, 12, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'mirror',
              }}
            >
              <img
                src='/assets/icons/scroll_icon.svg'
                alt='logo'
                width={48}
                height={48}
              />
            </MotionDiv>
            <p>scroll down</p>
          </MotionDiv>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default InitialSection;
