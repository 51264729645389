import styled from 'styled-components';

export const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;

  div {
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: 16px;
    font-weight: 400;
    color: #eea23f;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 870px) {
    flex-direction: column;
    margin-left: 7px;
    gap: 2px;

    h1 {
      font-size: 6.8px;
      font-weight: 400;
    }
  }
`;
