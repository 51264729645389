import useWindowSize from '../../../../hooks/useWindowSize';
import styles from './styles.module.css';

const DeviceExperienceSection = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <div className={styles.Container}>
      <div className={styles.ContentDiv}>
        <div className={styles.HeaderTextsDiv}>
          <h1 className={styles.HeaderTitle}>Multiple Devices Experiences</h1>
          <p className={styles.HeaderDescription}>
            In an era of remote connectivity, Bees stands out for its high
            integration with a wide range of devices, allowing you to access and
            promote your property, community real estate, where ever your are,
            when ever you want.
          </p>
        </div>
        <div className={styles.DevicesImageDiv}>
          {screenWidth < 500 ? (
            <>
              <img src={'/assets/images/tv.png'} alt={'tv'} />
              <img
                src={'/assets/images/portable_devices.png'}
                alt={'devices'}
              />
            </>
          ) : (
            <img src={'/assets/images/devices.png'} alt={'devices'} />
          )}
        </div>
        <div className={styles.InformationDiv}>
          <img
            src={'/assets/icons/devices_icon.svg'}
            alt={'devices'}
            width={32}
            height={32}
          />
          <div className={styles.InformationDivText}>
            Platform available on Touchscreen TVs, Desktops, Tablets and Mobile
            devices.
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceExperienceSection;
