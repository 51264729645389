import { MotionDiv } from '../MotionDiv';
import { useNavigate } from 'react-router-dom';
import { TitleDiv } from './styles';

const BeesHeaderLogo = () => {
  const navigate = useNavigate();

  return (
    <TitleDiv>
      <MotionDiv
        onClick={() => {
          navigate('/');
        }}
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 500 }}
      >
        <img
          src='/assets/icons/bees_logo.svg'
          alt='logo'
          width={91.67}
          height={24}
          style={{ cursor: 'pointer' }}
        />
      </MotionDiv>
      <h1>THE REAL ESTATE PLATFORM</h1>
    </TitleDiv>
  );
};

export default BeesHeaderLogo;
