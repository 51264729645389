import LoginForm from '../LoginForm';
import { MotionDiv } from '../MotionDiv';
import { ContainerWrapper } from './styles';
import styles from './styles.module.css';

const LoginModal = ({ closeLoginModal }: { closeLoginModal: () => void }) => {
  return (
    <ContainerWrapper>
      <div className={styles.Container}>
        <div className={styles.LoginContainerWrapper}>
          <div className={styles.LoginContainer}>
            <MotionDiv
              className={styles.LoginTitleDiv}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <h1>Welcome!</h1>
              <p>Please enter your login access.</p>
            </MotionDiv>
            <LoginForm />
          </div>
        </div>
        <div className={styles.BeesFooterLogoDiv}>
          <MotionDiv
            className={styles.BeesLogo}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={'/assets/icons/bees_logo.svg'}
              alt={'bees'}
              width={96}
              height={25}
            />
            <h2>PLATFORM</h2>
          </MotionDiv>
        </div>
      </div>
      <button className={styles.CloseButton} onClick={closeLoginModal}>
        <img
          src={'/assets/icons/close.svg'}
          alt={'close'}
          width={40}
          height={40}
        />
      </button>
    </ContainerWrapper>
  );
};

export default LoginModal;
