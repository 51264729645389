import styled, { keyframes } from 'styled-components';

export const ContainerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background: radial-gradient(50% 50% at 50% 50%, #6975866d 0%, #191c20e0 99.5%),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 16.15%
    ),
    linear-gradient(
      180deg,
      rgba(25, 28, 32, 0) 83.85%,
      rgba(25, 28, 32, 0.72) 100%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0.72) 0%,
      rgba(25, 28, 32, 0) 59.71%
    ),
    linear-gradient(
      90deg,
      rgba(25, 28, 32, 0) 60.43%,
      rgba(25, 28, 32, 0.72) 100%
    );
`;

export const TitleContainer = styled.div`
  padding: 120px 0 70px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  max-width: 1060px;

  @media (max-width: 1120px) {
    max-width: 80%;
    gap: 30px;
  }
`;

export const ContentTitle = styled.div`
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;

  @media (max-width: 1120px) {
    font-size: 40px;
  }

  @media (max-width: 680px) {
    font-size: 40px;
  }
`;

export const ContentDescription = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 28.9px;
  text-align: center;
  color: #ffffff;

  @media (max-width: 1120px) {
    font-size: 20px;
    line-height: 22.9px;
  }
`;

export const VideoContentContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 40px 20px;
  height: 100%;
`;

export const LeftSide = styled.div`
  min-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChooseVideoButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  border: none;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  background: ${({ isSelected }) => (isSelected ? '#FFFFFF14' : 'none')};
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? '1px 1px 5px 1px #ffffffa3 inset, 0px 109.54px 82.16px 0px #0000000d'
      : 'unset'};
  backdrop-filter: ${({ isSelected }) => (isSelected ? 'blur(10px)' : 'unset')};

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.09px;
    color: #ffffff;
  }

  &:hover {
    scale: ${({ isSelected }) => (isSelected ? 1 : 1.05)};
    transition: 0.5s;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const RightSide = styled.div`
  animation: ${fadeIn} 1.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  p {
    width: 80%;
    height: 42px;
    padding: 0 50px;
    text-align: center;
    font-weight: 400;
    line-height: 21.67px;
    color: #f3f3f3;
  }

  @media (max-width: 1160px) {
    p {
      width: 100%;
      padding: 0 20px;
    }
  }
`;

export const VideoDiv = styled.div`
  position: relative;
  width: 80%;
  border-radius: 8px;
  overflow: hidden;
  padding: 1.5px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: inherit;
    border: 1.5px solid transparent;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.15) 12.9%,
      #ffffff 56.31%,
      rgba(255, 255, 255, 0.15) 106.54%
    );
    pointer-events: none;
  }

  .video-container {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  @media (max-width: 1160px) {
    width: 100%;
  }
`;
