import styles from './styles.module.css';
import ImageSlider from './components/Slider';
import ScheduleDemoButton from '../../../../components/RequestDemoButton';
import { MotionButton } from '../../../../components/MotionDiv';
import useWindowSize from '../../../../hooks/useWindowSize';

const ImageGallerySection = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <div className={styles.ContainerWrapper}>
      <video
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline='true'
        className={styles.VideoBackground}
      >
        <source src='/assets/videos/hexagons.mp4' type='video/mp4' />
      </video>
      <div className={styles.Container}>
        <div className={styles.ScheduleButtonDiv}>
          <ScheduleDemoButton />
        </div>
        <div className={styles.SliderContainer}>
          <ImageSlider />
          {screenWidth > 534 && (
            <>
              <MotionButton
                style={{ left: 30 }}
                className={`image-swiper-button-prev ${styles.SlideNavigationButton}`}
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <img
                  src={'/assets/icons/right_arrow_icon.svg'}
                  alt={'arrow'}
                  width={26}
                  height={26}
                  style={{ transform: 'rotate(180deg)' }}
                />
              </MotionButton>
              <MotionButton
                className={`image-swiper-button-next ${styles.SlideNavigationButton}`}
                style={{ right: 30 }}
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <img
                  src={'/assets/icons/right_arrow_icon.svg'}
                  alt={'arrow'}
                  width={26}
                  height={26}
                />
              </MotionButton>
            </>
          )}

          <div className={styles.PaginationDiv}>
            <div className='swiper-custom-pagination CustomSwiperPagination' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallerySection;
