import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MotionDiv } from '../../components/MotionDiv';
import useWindowSize from '../../hooks/useWindowSize';
import styles from './styles.module.css';

const About = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <div className={styles.ContainerWrapper} id='scrollableDiv'>
      <div className={styles.Container}>
        <div className={styles.HeaderWrapper}>
          <Header />
        </div>
        <div className={styles.ContentDiv}>
          <MotionDiv
            className={styles.LeftSideDiv}
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            <div className={styles.FirstLeftSideContentDiv}>
              <img
                src={'/assets/icons/bees_b_logo.svg'}
                alt={'bees'}
                width={34}
                height={38}
              />
              <div style={{ marginTop: 14, marginBottom: 20 }}>
                <h1 className={styles.InformationDivTitle}>About us</h1>
              </div>
              <p className={styles.InformationDivDescription}>
                At Bees, we are more than just a company - we are a community of
                visionaries, passionate about redefining the boundaries of
                what’s possible in the real estate sector. We bring together
                people and ideas on a shared journey towards the future of real
                estate development. Our company was born from the urgent need to
                digitalize the real estate market, making it more efficient,
                accessible, and technological. At Bees, we not only embrace
                change, but actively pursue it. We are constantly innovating and
                adapting, staying ahead of industry trends and meeting the
                ever-evolving needs of our clients.
              </p>
              <p
                className={styles.InformationDivDescription}
                style={{ marginTop: 14 }}
              >
                Above all, we are driven by a shared passion for the limitless
                potential of the real estate sector. We believe that together,
                we can build a future where creativity flourishes, boundaries
                disappear, and opportunities are truly endless.
              </p>
            </div>
            <div className={styles.SecondLeftSideContentDiv}>
              <div style={{ marginTop: 14, marginBottom: 20 }}>
                <h1 className={styles.InformationDivTitle}>
                  Customer Service And Support
                </h1>
              </div>
              <p className={styles.InformationDivDescription}>
                Contact us based on your location at the addresses and phone
                numbers of our studios next door or via our main email{' '}
                <span className={styles.EmailLink}>info@go-bees.com</span>.
              </p>
            </div>
          </MotionDiv>
          {screenWidth >= 1300 && (
            <MotionDiv
              className={styles.RightSideDiv}
              initial={{ opacity: 0, x: 300 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              <div className={styles.RightSideFirstContentDiv}>
                <h2 className={styles.RightSideTitle}>BEES HD</h2>
                <div className={styles.LocationInformationDiv}>
                  <img
                    src={'/assets/icons/location_icon.svg'}
                    alt={'location'}
                    width={32}
                    height={32}
                  />
                  <div className={styles.LocationInformationTextDiv}>
                    <p className={styles.LocationInfomationText}>MIAMI</p>
                    <p className={styles.LocationInfomationText}>
                      78 SW 7th St,
                    </p>
                    <p className={styles.LocationInfomationText}>
                      Miami | FL | 33130
                    </p>
                    <p className={styles.LocationInfomationText}>USA</p>
                    <p className={styles.LocationInfomationText}>
                      +1 305 301 5819
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.RightSideSecondContentDiv}>
                <h2 className={styles.RightSideTitle}>BEES around the world</h2>
                <div className={styles.EmailDiv}>
                  <img
                    src={'/assets/icons/email_icon.svg'}
                    alt={'email'}
                    width={32}
                    height={32}
                  />
                  <p className={styles.EmailText}>info@go-bees.com</p>
                </div>
              </div>
            </MotionDiv>
          )}
        </div>
      </div>
      <Footer
        paddingTop={150}
        paddingRight={80}
        paddingBottom={0}
        paddingLeft={80}
      />
    </div>
  );
};

export default About;
