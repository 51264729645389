import Locations from '../../../Locations';
import AboutButton from '../AboutButton';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

const MobileFilter = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    window.location.href = 'mailto:info@go-bees.com';
  };

  return (
    <div className={styles.Container}>
      <div className={styles.FooterContentDiv}>
        <div className={styles.BeesLogoDiv}>
          <img
            className={styles.BeesLogoImg}
            src={'/assets/icons/bees_logo.svg'}
            alt={'bees'}
          />
          <p className={styles.BeesLogoText}>THE REAL ESTATE PLATFORM</p>
        </div>
        <div className={styles.ContentDiv}>
          <div className={styles.RouteDivContainer}>
            <div className={styles.RouteDiv}>
              <h2 className={styles.RouteDivTitle} style={{ textAlign: 'end' }}>
                Company
              </h2>
              <div className={styles.RouteButtonsDiv}>
                <div>
                  <AboutButton />
                </div>
                <div>
                  <button
                    className={styles.RouteButton}
                    onClick={handleContactClick}
                  >
                    Contact
                  </button>
                </div>
                <div>
                  <button
                    className={styles.RouteButton}
                    onClick={() => {
                      navigate('/privacy_policy');
                    }}
                  >
                    Privacy Policy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.RouteDiv}>
        <h2
          className={styles.RouteDivTitle}
          style={{ textAlign: 'start', width: '100%', marginBottom: 8 }}
        >
          Locations
        </h2>
        <Locations />
      </div>
      <div className={styles.CopyrightDiv}>
        <p className={styles.CopyrightText}>
          All rights reserved © 2024 | BEES, The Real Estate Platform.
        </p>
      </div>
    </div>
  );
};

export default MobileFilter;
