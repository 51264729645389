import styled from 'styled-components';

export const MainContainer = styled.div`
  @font-face {
    font-family: 'AauxProMedium';
    src: url('/fonts/AauxProMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'AauxProBold';
    src: url('/fonts/AauxProBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    font-family: 'AauxProMedium', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
