'use client';
import styles from './styles.module.css';
import VideoSlider from '../VideoSlider';
import { useRef, useState } from 'react';

const VideosPlayer = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [videos, setVideos] = useState([
    {
      index: 0,
      id: 1,
      isPlaying: false,
      source: '/assets/video_player/videos/home.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/arrow_right.svg',
      title: 'Start Experience | Home Page',
      showDescription: true,
      description:
        'Home page designed to run in stand by mode, providing a video of the developers project that runs continuously, one click away from the start experience.',
    },
    {
      index: 1,
      id: 2,
      isPlaying: false,
      source: '/assets/video_player/videos/360.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-02-360immersive.webp',
      icon: '/assets/icons/360.svg',
      title: '360 Degree Immersive Experience',
      showDescription: true,
      description:
        'Step into a fully immersive experience with a seamless 360-degree view, offering unparalleled depth and interactivity at your fingertips.',
    },
    {
      index: 2,
      id: 3,
      isPlaying: false,
      source: '/assets/video_player/videos/suntrack.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/timeline.svg',
      title: 'Timeline Day to Night',
      showDescription: true,
      description:
        'Experience the passage of time firsthand with our dynamic day-to-night timeline feature, seamlessly transitioning through different lighting and conditions.',
    },
    {
      index: 3,
      id: 4,
      isPlaying: false,
      source: '/assets/video_player/videos/virtual_tour.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/virtual_tour_icon.svg',
      title: 'Live | Virtual Tour',
      showDescription: true,
      description:
        'Embark on a real-time exploration or virtual journey through our interactive tour, bridging the gap between physical presence and digital immersion effortlessly.',
    },
    {
      index: 4,
      id: 5,
      isPlaying: false,
      source: '/assets/video_player/videos/floorplan.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/floorplan.svg',
      title: 'Floor Plan Gallery and Comparison',
      showDescription: true,
      description:
        'Compare floor plans effortlessly with our interactive gallery, designed for easy side-by-side viewing and detailed analysis.',
    },
    {
      index: 5,
      id: 6,
      isPlaying: false,
      source: '/assets/video_player/videos/gallery.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/gallery.svg',
      title: 'Gallery Images',
      showDescription: true,
      description:
        'Explore our curated gallery of high-resolution images, showcasing every detail and perspective of the project.',
    },
    {
      index: 6,
      id: 7,
      isPlaying: false,
      source: '/assets/video_player/videos/film.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/video.svg',
      title: 'Film Presentation',
      showDescription: true,
      description:
        'Dive into our collection of engaging videos, offering an in-depth look at the project’s features and allowing you to see the project in more detail.',
    },
    {
      index: 7,
      id: 8,
      isPlaying: false,
      source: '/assets/video_player/videos/map.mp4',
      ref: useRef<HTMLVideoElement>(null),
      thumbnail: '/assets/video_player/images/slider-01-home.webp',
      icon: '/assets/icons/map.svg',
      title: 'Interactive Map',
      showDescription: true,
      description:
        'Navigate through the project with our interactive map, featuring main points of interest in the surroundings and their distances.',
    },
  ]);

  const PlayOrPauseVideo = (index: number) => {
    setVideos((prevState) =>
      prevState.map((video) => {
        if (video.index === index) {
          return { ...video, isPlaying: !video.isPlaying };
        }
        return { ...video, isPlaying: false };
      })
    );
  };

  const pauseAllVideos = () => {
    setVideos((prevState) =>
      prevState.map((video) => ({ ...video, isPlaying: false }))
    );
  };

  return (
    <div className={styles.ContentDiv}>
      <VideoSlider
        videos={videos}
        PlayOrPauseVideo={PlayOrPauseVideo}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
        pauseAllVideos={pauseAllVideos}
      />
      <div className={styles.BottomVideoContentDiv}>
        <div className={styles.PaginationDiv}>
          <div className='swiper-video-custom-pagination CustomSwiperPagination' />
        </div>
      </div>
    </div>
  );
};

export default VideosPlayer;
