import React from 'react';
import Select, { SingleValue } from 'react-select';
import { customErrorStyles, customStyles } from './styles';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectInputProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  hasError: boolean;
}

const CustomSelectInput: React.FC<CustomSelectInputProps> = ({
  options,
  value,
  hasError,
  onChange,
}) => {
  const selectedValue =
    options.find((option) => option.value === value) || null;

  const handleChange = (selectedOption: SingleValue<Option>) => {
    onChange(selectedOption ? selectedOption.value : '');
  };

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={handleChange}
      styles={hasError ? customErrorStyles : customStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default CustomSelectInput;
