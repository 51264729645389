import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  background: #191c20;
  padding: 20px 80px 0 80px;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(37, 37, 37);
  }

  &::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #616161;
  }

  @media (max-width: 750px) {
    padding: 20px 20px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  img {
    width: 56px;
  }

  h1 {
    font-family: 'AauxProBold', sans-serif;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
  }
`;

export const TitleTextsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 657px;

  p {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 16.41px;
    color: #e6e6e6;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 80px;
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: center;
    color: #ededed;
  }
`;

export const ContactEmailDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;

  img {
    width: 16px;
    height: 16px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #eea23f;
    text-decoration: underline;
  }
`;

export const ContentList = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 657px;

  @media (max-width: 751px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  border-bottom: 1px solid #808fa3;
  margin-top: 30px;
`;

export const ContentButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  padding: 16px;

  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }

  @media (max-width: 425px) {
    padding: 16px 8px;
    h2 {
      font-size: 14px;
    }
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 12px 16px 12px;

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
    color: #ededed;
  }
`;
