import useWindowSize from '../../hooks/useWindowSize';
import DesktopFooter from './components/DesktopFooter';
import MobileFooter from './components/MobileFooter';

const Footer = ({
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
}: {
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
}) => {
  const { width: screenWidth } = useWindowSize();

  return (
    <>
      {screenWidth > 700 ? (
        <DesktopFooter
          paddingTop={paddingTop}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          paddingBottom={paddingBottom}
        />
      ) : (
        <MobileFooter />
      )}
    </>
  );
};

export default Footer;
