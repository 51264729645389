export const desktopImages = [
  {
    id: '1',
    image: '/assets/slider_images/slider_image1.webp',
  },
  {
    id: '2',
    image: '/assets/slider_images/slider_image2.webp',
  },
  {
    id: '3',
    image: '/assets/slider_images/slider_image3.webp',
  },
  {
    id: '4',
    image: '/assets/slider_images/slider_image4.webp',
  },
  {
    id: '5',
    image: '/assets/slider_images/slider_image5.webp',
  },
];

export const mobileImages = [
  {
    id: '1',
    image: '/assets/slider_images_mobile/slider_image1.webp',
  },
  {
    id: '2',
    image: '/assets/slider_images_mobile/slider_image2.webp',
  },
  {
    id: '3',
    image: '/assets/slider_images_mobile/slider_image3.webp',
  },
  {
    id: '4',
    image: '/assets/slider_images_mobile/slider_image4.webp',
  },
  {
    id: '5',
    image: '/assets/slider_images_mobile/slider_image5.webp',
  },
  {
    id: '6',
    image: '/assets/slider_images_mobile/slider_image6.webp',
  },
];
