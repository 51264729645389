import styles from './styles.module.css';

const Locations = () => {
  return (
    <div className={styles.LocationsDiv}>
      <div className={styles.LocationCard}>
        <h2 className={styles.LocationTitle}>/United States</h2>
        <div className={styles.LocationInformationDiv}>
          <img
            src={'/assets/icons/location_icon.svg'}
            alt={'location'}
            width={17}
            height={19}
          />
          <div className={styles.LocationInformationTextDiv}>
            <p className={styles.LocationInfomationText}>MIAMI</p>
            <p className={styles.LocationInfomationText}>78 SW 7th St,</p>
            <p className={styles.LocationInfomationText}>Miami | FL | 33130</p>
            <p className={styles.LocationInfomationText}>USA</p>
            <p className={styles.LocationInfomationText}>+1 305 301 5819</p>
          </div>
        </div>
      </div>
      <div className={styles.LocationCard}>
        <h2 className={styles.LocationTitle}>/Canada</h2>
        <div className={styles.LocationInformationDiv}>
          <img
            src={'/assets/icons/location_icon.svg'}
            alt={'location'}
            width={17}
            height={19}
          />
          <div className={styles.LocationInformationTextDiv}>
            <p className={styles.LocationInfomationText}>VANCOUVER</p>
            <p className={styles.LocationInfomationText}>595 Burrard St,</p>
            <p className={styles.LocationInfomationText}>Vancouver | BC</p>
            <p className={styles.LocationInfomationText}>V7X1L4 | Canada</p>
            <p className={styles.LocationInfomationText}>+1 778 855 5529</p>
          </div>
        </div>
      </div>
      <div className={styles.LocationCard}>
        <h2 className={styles.LocationTitle}>/Brazil</h2>
        <div className={styles.LocationInformationDiv}>
          <img
            src={'/assets/icons/location_icon.svg'}
            alt={'location'}
            width={17}
            height={19}
          />
          <div className={styles.LocationInformationTextDiv}>
            <p className={styles.LocationInfomationText}>CURITIBA</p>
            <p className={styles.LocationInfomationText}>
              Al. Carlos de Carvalho
            </p>
            <p className={styles.LocationInfomationText}>
              373, 5th Floor | Curitiba
            </p>
            <p className={styles.LocationInfomationText}>PR | 80410-180</p>
            <p className={styles.LocationInfomationText}>+55 41 98838 4112</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
