import ScheduleDemoButton from '../../../../components/RequestDemoButton';
import styles from './styles.module.css';

const DashboardSection = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.ContentDiv}>
        <div className={styles.HeaderTextsDiv}>
          <div className={styles.HeaderTitleDiv}>
            <img src={'/assets/icons/bees_logo.svg'} alt={'bees'} />
            <h1 className={styles.HeaderTitle}>DASHBOARD</h1>
          </div>
          <p className={styles.HeaderDescription}>
            Dashboard full control for keep your projects always up to date with
            an intuitive dashboard. Register new content and adjust details with
            ease, ensuring that your online presence is always relevant and
            attractive.
          </p>
        </div>
        <div className={styles.DashboardImageDiv}>
          <img src={'/assets/images/dashboard.png'} alt={'dashboard'} />
        </div>
        <ScheduleDemoButton />
      </div>
    </div>
  );
};

export default DashboardSection;
