import { Container, TitleDiv } from './styles';
import VideosPlayer from './components/VideosPlayer';

const MobileVideoGallerySection = () => {
  return (
    <Container>
      <TitleDiv>
        <h1>Explore all Features in Detail</h1>
      </TitleDiv>
      <VideosPlayer />
    </Container>
  );
};

export default MobileVideoGallerySection;
