const ContentTexts1 = () => {
  return (
    <>
      <p>Age and eligibility requirements</p>
      <p>
        To use the Bees Service and access any Content, you must (1) be 18 years
        of age (or the equivalent minimum age in your country of origin) or
        older, (2) have parental or legal guardian consent if you are under your
        country of origin, (3) have the power to form a binding contract with us
        and are not prevented from doing so by any applicable law, and (4)
        reside in a country where the Service is available. You also promise
        that any registration information you submit to Bees is true, accurate
        and complete, and you agree to keep it so at all times. If you are a
        minor in your home country, your parent or legal guardian will need to
        agree to these Terms on your behalf.
      </p>
    </>
  );
};

const ContentTexts2 = () => {
  return (
    <>
      <p>BEES Service Options</p>
      <p>
        We offer several BEES Service options. Certain options of the BEES
        Service are provided free of charge, while others require payment before
        they can be accessed (the “Paid Subscriptions”). We may also offer
        special promotional plans, memberships or services. We are not
        responsible for the products and services provided by third parties.
      </p>
      <p>
        Special Services may not be available to all users. We will explain what
        services are available to you when you are signing up for services. If
        you cancel your subscription to the Special Services, or if your
        subscription to the Special Services is interrupted (for example, if you
        change your payment details), you may not be able to re-subscribe to the
        Special Services.
      </p>
      <p>Trials</p>
      <p>
        From time to time, we or others on our behalf may offer trials of Paid
        Subscriptions for a specified period without payment or at a reduced
        rate (a “Trial”). By using a BEES Service through a Trial, you agree to
        the BEES Promotional Trial Terms.
      </p>
      <p>Third Party Applications and Devices</p>
      <p>
        The BEES Service may be integrated with, or may otherwise interact with,
        third-party applications, websites and services (“Third-Party
        Applications”) and third-party devices. Your use of such Third-Party
        Applications and Devices may be subject to additional terms, conditions
        and policies provided to you by the applicable third party. BEES does
        not guarantee that Third Party Applications and Devices will be
        compatible with the BEES Service.
      </p>
      <p>Service Limitations and Modifications</p>
      <p>
        We use reasonable care and skill to keep the BEES Service operational
        and provide you with an immersive real estate experience. However, our
        service offerings and their availability may change from time to time
        and are subject to applicable laws, without liability to you. For
        example:
      </p>
      <p>
        The BEES Service may experience temporary interruptions due to technical
        difficulties, maintenance or testing, or updates, including those
        necessary to reflect changes in relevant laws and regulatory
        requirements.
      </p>
      <p>
        We aim to constantly evolve and improve our Service, and may modify,
        suspend or stop (permanently or temporarily) providing all or part of
        the BEES Service (including specific functions, features, subscription
        plans and promotional offers).
      </p>
      <p>
        BEES is under no obligation to provide any particular content through
        the Bees Service, and BEES or the applicable owners may remove
        particulars such as images, videos, floor plans, 3D spins and other
        content without notice.
      </p>
      <p>
        If you have paid fees directly to BEES for a Subscription that BEES
        discontinues before the end of your Prepaid Period, BEES will refund the
        prepaid fees for the unused Prepaid Period of your then-current Paid
        Subscription following such discontinuation. . Your account and billing
        information must be up to date for us to refund you.
      </p>
      <p>
        BEES has no liability to you, nor any obligation to provide a refund to
        you, in connection with internet or other service interruptions or
        failures that are caused by the actions of government authorities, other
        third parties or events beyond our control.
      </p>
    </>
  );
};

const ContentTexts3 = () => {
  return (
    <>
      <p>
        Collected when you sign up for the BEES Service or update your account
      </p>
      <p>User data:</p>
      <p>
        Personal data necessary to create your BEES account and allow you to use
        the BEES Service. The type of data collected and used depends on the
        type of Service Option you have, the country you are in and whether you
        use third-party services to log in. This may include:
      </p>
      <div>
        <p>Profile name</p>
        <p>Email address</p>
        <p>Password</p>
        <p>Phone number</p>
        <p>Date of birth</p>
        <p>Gender</p>
        <p>Home address</p>
      </div>
      <p>Residential Address Data:</p>
      <p>
        We may request and process your home address for the following reasons:
      </p>
      <div>
        <p>Verify eligibility for a Service Option;</p>
        <p>Send notices required by law;</p>
        <p>Provide support options;</p>
        <p>Billing and tax administration;</p>
        <p>Deliver physical goods or gifts that you requested;</p>
      </div>
      <p>Collected through your use of the BEES Service:</p>
      <p>
        Personal data collected and processed about you when accessing or using
        the BEES Service. There are a few types of information included in this,
        listed in the following sections.
      </p>
      <div>
        <p>Information about how you use BEES</p>
        <p>Examples include:</p>
      </div>
      <div>
        <p>Information about your BEES Service Option</p>
        <p>Actions with the BEES Service (including date and time), such as:</p>
      </div>
      <div>
        <p>Search queries;</p>
        <p>Browsing history;</p>
        <p>Account configurations.</p>
      </div>
      <div>
        <p>
          Your use of third-party services, devices and applications in
          connection with the BEES ServiceInferences (i.e., our understanding)
          of your interests and preferences based on your use of the BEES
          ServiceContent you provide when participating in BEES promotions, such
          as contests or sweepstakes
        </p>
        <p>
          Content you post to any part of the BEES Service, such as images,
          audio, text, titles, descriptions, communications and other types of
          content
        </p>
      </div>
      <p>Your technical data:</p>
      <p>Examples include:</p>
      <div>
        <p>URL information</p>
        <p>Online identifiers, such as cookie data and IP addresses</p>
        <p>
          Information about the devices you use, such as device IDs, network
          connection type, provider, network and device performance, browser
          type, language, information that enables digital rights management,
          operating system, and information that allows us to discover and
          connect to third-party devices and applications.
        </p>
      </div>
      <p>Your general location:</p>
      <p>
        Your general location includes country, region, or state. We need this
        to:
      </p>
      <div>
        <p>
          Meet geographic requirements in our agreements with content owners on
          the BEES Service
        </p>
        <p>Deliver relevant content and advertising to you</p>
      </div>
      <p>Sensor data from your device:</p>
      <p>
        Device sensor data generated by motion or orientation, if necessary to
        provide features of the BEES Service that require such data. This is
        data that your device collects about the way you move or hold the
        device.
      </p>
      <div>
        <p>
          Payment and Purchase Data: If you make purchases on BEES or sign up
          for a paid Service Option or trial, we will need to process your
          payment data.
        </p>
        <p>
          Survey Data: When you respond to a survey or participate in user
          surveys.
        </p>
      </div>
    </>
  );
};

const ContentTexts4 = () => {
  return (
    <>
      <p>
        Here is a general explanation of each ‘legal basis’ to help you
        understand the table:
      </p>
      <div>
        <p>
          Performance of a Contract: When it is necessary for BEES (or a third
          party) to process your personal data to:
        </p>
        <p>
          - Fulfill obligations under a contract with you. This includes BEES's
          obligations under the Terms of Use to provide the BEES Service to you.
        </p>
        <p>- Verify information before a new contract with you begins.</p>
      </div>
      <p>
        Legitimate Interest: When BEES or a third party has an interest in using
        your personal data in a specific way, which is necessary and justified
        considering any possible risks to you and other BEES users. For example,
        using your Usage Data to improve the BEES Service for all users. Get in
        touch if you want to understand a specific justification.
      </p>
      <p>
        Consent: When BEES asks you to actively indicate your agreement to BEES
        using your personal data for a certain purpose.
      </p>
      <p>
        Compliance with Legal Obligations: When BEES must process your personal
        data to comply with a law.
      </p>
      <p>Purposes for processing your data:</p>
      <div>
        <p>
          To provide the BEES Service in accordance with our contract with you.
        </p>
        <p>For example, when we use your personal data to:</p>
        <p>- Set up an account for you.</p>
        <p>- Customize your account.</p>
        <p>To provide other parts of the BEES Service.</p>
        <p>
          - For example, when we use your personal data to allow you to share a
          link to BEES content with another person.
        </p>
        <p>
          To diagnose, troubleshoot, and fix problems with the BEES Service.
        </p>
        <p>
          To evaluate and develop new features, technologies and improvements to
          the BEES Service.
        </p>
        <p>
          For marketing or advertising where the law requires us to collect your
          consent.
        </p>
        <p>
          For other marketing, promotional and advertising purposes where the
          law does not require consent.
        </p>
        <p>To comply with a legal obligation to which we are subject.</p>
        <p>
          - For example, when we use your date of birth when necessary for age
          verification purposes.
        </p>
        <p>
          To comply with a request from law enforcement, courts or other
          competent authorities.
        </p>
        <p>To fulfill contractual obligations with third parties.</p>
        <p>
          To take appropriate action with reports of intellectual property
          infringement and inappropriate content.
        </p>
        <p>To conduct research.</p>
      </div>
      <p>
        In jurisdictions where legitimate interest is not recognized as a legal
        basis, we rely on contractual necessity or consent.
      </p>
    </>
  );
};

const ContentTexts5 = () => {
  return (
    <>
      <p>
        This section establishes who receives personal data collected or
        generated through your use of the BEES Service.
      </p>
      <p>Publicly available information:</p>
      <p>
        The following personal data will always be publicly available on the
        BEES Service:
      </p>
      <div>
        <p>- Your profile name</p>
        <p>- Your profile picture</p>
        <p>
          - Other content you post to the BEES Service, and any associated
          titles, descriptions and images
        </p>
      </div>
      <p>
        You or another user may share certain information on third-party
        services, such as social media or messaging platforms. That includes:
      </p>
      <div>
        <p>- Your profile</p>
        <p>- Any content you post on BEES and details about that content</p>
        <p>- Any titles, descriptions and associated images</p>
      </div>
      <p>
        When this sharing occurs, the third-party service may store a copy to
        support its features.
      </p>
      <p>Personal data you may choose to share:</p>
      <p>
        We will only share the following personal data with those described
        below:
      </p>
      <div>
        <p>
          - When you choose to use a feature of the BEES Service, or a
          third-party application, service or device, and we need to share
          personal data to enable this, or
        </p>
        <p>- If you grant us permission to share personal data.</p>
      </div>
      <p>
        Third-party applications, services, and devices that you connect to your
        BEES account:
      </p>
      <div>
        <p>- User data</p>
        <p>- Usage data</p>
      </div>
      <p>
        To connect your BEES account, or so that you may use the BEES Service in
        connection with third-party applications, services or devices.
      </p>
      <div>
        <p>
          To share information about your use of the BEES Service with other
          BEES users.
        </p>
        <p>- User data</p>
      </div>
      <p>To receive news or promotional offers from partners.</p>
      <p>Information we may share:</p>
      <p>See the text below for details of who we share it with and why.</p>
      <div>
        <p>Service Providers</p>
        <p>- User data</p>
        <p>- Address Data</p>
        <p>- Usage Data</p>
        <p>- Payment and Purchase Data</p>
        <p>- Research and Study Data</p>
      </div>
      <p>So that they can provide their services to BEES.</p>
      <div>
        <p>These service providers include those we engage to:</p>
        <p>- Provide customer support</p>
        <p>
          - Operate the technical infrastructure necessary to provide the BEES
          Service
        </p>
        <p>
          - Help protect and secure our systems and services (e.g. Google's
          reCAPTCHA)
        </p>
        <p>- Help market BEES products, services, events and promotions</p>
      </div>
      <p>Payment Partners:</p>
      <div>
        <p>- User data</p>
        <p>- Payment and Purchase Data</p>
      </div>
      <p>
        So that they can process your payments and for fraud prevention
        purposes.
      </p>
      <p>Advertising Partners:</p>
      <div>
        <p>- User data</p>
        <p>- Usage Data</p>
      </div>
      <div>
        <p>
          So they can help us provide more relevant advertising to you on the
          BEES Service and help measure the effectiveness of ads.
        </p>
        <p>
          For example, our advertising partners help us facilitate personalized
          advertising.
        </p>
      </div>
      <p>Marketing Partners:</p>
      <div>
        <p>- User data</p>
        <p>- Usage Data</p>
      </div>
      <div>
        <p>
          To promote BEES with our partners. We share certain User Data and
          Usage Data with these partners when necessary to:
        </p>
        <p>
          - Allow you to participate in BEES promotions, including trials or
          other bundled offers
        </p>
        <p>
          - Promote BEES in media and advertisements published on other online
          services
        </p>
        <p>
          - Help us and our partners measure the effectiveness of BEES
          promotions
        </p>
      </div>
      <p>Academic Researchers</p>
      <div>
        <p>- User data</p>
        <p>- Usage Data</p>
      </div>
      <p>
        For activities such as statistical analysis and academic study, but only
        in a pseudonymized format.
      </p>
      <p>Other BEES group companies, including companies acquired by BEES</p>
      <div>
        <p>- User data</p>
        <p>- Address Data</p>
        <p>- Usage Data</p>
        <p>- Payment and Purchase Data</p>
        <p>- Research and Study Data</p>
      </div>
      <p>
        To carry out our daily business operations and so that we can maintain,
        improve and provide the BEES Service and acquired business services to
        you.
      </p>
      <p>
        Law enforcement and other authorities, or other parties to litigation
      </p>
      <div>
        <p>- User data</p>
        <p>- Usage Data</p>
      </div>
      <div>
        <p>
          When we believe in good faith that it is necessary to do so, for
          example:
        </p>
        <p>- To comply with a legal obligation</p>
        <p>
          - To respond to valid legal process (such as a search warrant, court
          order, or subpoena)
        </p>
        <p>- For our own interest or that of third parties, related to:</p>
        <p>- National security</p>
        <p>- Law enforcement</p>
        <p>- Litigation (a court case)</p>
        <p>- Criminal investigation</p>
        <p>- Protecting someone's safety</p>
        <p>- Prevention of death or imminent bodily harm</p>
      </div>
      <p>Buyers of our business</p>
      <div>
        <p>- User data</p>
        <p>- Address Data</p>
        <p>- Usage Data</p>
        <p>- Payment and Purchase Data</p>
        <p>- Research and Study Data</p>
      </div>
      <div>
        <p>
          If we sold or negotiated the sale of our business to a buyer or
          prospective buyer.
        </p>
        <p>
          In that case, we may transfer your personal data to a successor or
          affiliate as part of that transaction.
        </p>
      </div>
    </>
  );
};

const ContentTexts6 = () => {
  return (
    <>
      <p>
        We keep your personal data only for as long as necessary to provide the
        BEES Service and for BEES' legitimate and essential business purposes,
        such as:
      </p>
      <div>
        <p>- Maintain the performance of the BEES Service</p>
        <p>
          - Make data-driven business decisions about new features and offerings
        </p>
        <p>- Comply with our legal obligations</p>
        <p>- Resolve disputes</p>
      </div>
      <p>
        Here are some of the categories of our retention periods and the
        criteria we use to determine them:
      </p>
      <div>
        <p>Data retained until you remove it</p>
        <p>
          It is your right to request that we delete certain of your personal
          data.
        </p>
        <p>
          You can also delete certain personal data directly from the BEES
          Service: for example, you can edit or delete your profile photo. When
          users can view and update personal data themselves, we keep the
          information for as long as the user chooses, unless one of the limited
          purposes described below applies.
        </p>
      </div>
      <div>
        <p>Data that expires after a specific period</p>
        <p>
          We set certain retention periods so that some data expires after a
          specific period. For example, personal data that you may enter as part
          of search queries is generally deleted after 90 days.
        </p>
      </div>
      <div>
        <p>Data retained until your BEES account is deleted</p>
        <p>
          We keep some data until your BEES account is deleted. Examples of this
          include your BEES username and profile information. We also tend to
          keep activity history for the life of an account, for example, to
          provide personalized content that takes usage habits into account.
          When your BEES account is deleted, this category of data is deleted or
          de-identified.
        </p>
      </div>
      <div>
        <p>Data retained for extended periods of time for limited purposes</p>
        <p>
          After your account is deleted, we keep some data for a longer period
          of time, but for very limited purposes. For example, we may be subject
          to legal or contractual obligations that require this. These may
          include mandatory data retention laws, government orders to preserve
          data relevant to an investigation, or data held for litigation
          purposes. We may also retain data that has been removed from the BEES
          Service for a limited period of time. This could be:
        </p>
      </div>
      <div>
        <p>- to help ensure user safety, or</p>
        <p>- to protect against harmful content on our platform.</p>
      </div>
      <p>
        This helps us investigate possible violations of our Usage Guidelines
        and Platform Rules. On the other hand, we will remove illegal content if
        the law requires us to.
      </p>
    </>
  );
};

const ContentTexts7 = () => {
  return (
    <>
      <p>
        Due to the global nature of our business, BEES shares personal data
        internationally with BEES group companies, subcontractors and partners
        to provide the BEES Service to you. They may process your data in
        countries whose data protection laws are not considered as strong as
        local laws. For example, they may not grant you the same rights over
        your data.
      </p>
      <p>
        Whenever we transfer personal data internationally, we use tools to:
      </p>
      <div>
        <p>- Ensure data transfer complies with applicable law</p>
        <p>
          - Help provide your data with the same level of protection as it has
          locally
        </p>
      </div>
      <p>
        To ensure that each data transfer complies with applicable law, we use
        the following legal mechanisms:
      </p>
      <div>
        <p>
          - Standard Contractual Clauses ('CCPs'). These clauses require the
          other party to protect your data and provide you with rights and
          protections at the local level. For example, we use CCPs to transfer
          the personal data described in Section 3 'Personal data we collect
          about you' to our hosting provider, which uses servers in the USA. You
          can exercise your rights under the Standard Contractual Clauses by
          contacting us or the third party processing your personal data.
        </p>
        <p>
          - Adequacy Decisions. This means that we transfer personal data to
          countries outside the area that have adequate laws to protect personal
          data, as determined by the competent authority. For example, we
          transfer the personal data described in Section 3 'Personal data we
          collect about you' to providers based in countries with legislation
          deemed appropriate.
        </p>
      </div>
      <p>
        We also identify and use additional safeguards as appropriate for each
        data transfer. For example, we use:
      </p>
      <div>
        <p>- Technical protections such as encryption and pseudonymization</p>
        <p>
          - Policies and processes to challenge disproportionate or illegal
          requests from government authorities.
        </p>
      </div>
    </>
  );
};

const ContentTexts8 = () => {
  return (
    <>
      <p>
        We are committed to protecting our users' personal data. We implement
        appropriate technical and organizational measures to help protect the
        security of your personal data. However, be aware that no system is
        completely secure.
      </p>
      <p>
        We implement several safeguards to protect against unauthorized access
        and unnecessary retention of personal data on our systems. These include
        pseudonymization, encryption, access and retention policies.
      </p>
      <p>To protect your user account, we encourage you to:</p>
      <div>
        <p>- Use a strong password that you only use for your BEES account</p>
        <p>- Never share your password with anyone</p>
        <p>- Limit access to your computer and browser</p>
        <p>
          - Log out once you have finished using the BEES Service on a shared
          device
        </p>
        <p>- Read more details about how to protect your account</p>
      </div>
      <p>
        If other people have access to your BEES account, they will be able to
        access personal data, controls and the BEES Service available in your
        account. For example, you may have allowed someone to use your account
        on a shared device.
      </p>
      <p>
        It is your responsibility to allow only authorized people to use your
        account where you are comfortable sharing that personal data with them.
        Use of your BEES account by anyone else may affect your personalized
        recommendations and be included in your data download.
      </p>
    </>
  );
};

const ContentTexts9 = () => {
  return (
    <>
      <p>
        The Bees Service has a minimum 'Age Limit' in each country or region.
        The BEES Service is not directed to children whose age:
      </p>
      <div>
        <p>- Be under 13 years old</p>
        <p>- Make it illegal to process your personal data, or</p>
        <p>- Require parental consent to process your personal data</p>
      </div>
      <p>
        We do not knowingly collect or use personal data from children under the
        applicable Age Limit. If you are under the Age Limit, do not use the
        BEES Service and do not provide any personal data to us.
      </p>
      <p>
        If you are the parent of a child under the Age Limit and you become
        aware that your child has provided personal data to BEES, please contact
        us.
      </p>
      <p>
        If we discover that we have collected personal data from a child under
        the applicable Age Limit, we will take reasonable steps to delete the
        personal data. This may require us to delete the BEES account for that
        child.
      </p>
      <p>
        When using a shared device on the BEES Core Service, be careful when
        playing or recommending any content that is inappropriate for people
        under the age of 18.
      </p>
    </>
  );
};

const ContentTexts10 = () => {
  return (
    <>
      <p>We may make changes to this Policy from time to time.</p>
      <p>
        When we make material changes to this Policy, we will provide you with
        prominent notice, as appropriate under the circumstances. For example,
        we may display a prominent notice within the BEES Service or send an
        email or device notification.
      </p>
    </>
  );
};

export {
  ContentTexts1,
  ContentTexts2,
  ContentTexts3,
  ContentTexts4,
  ContentTexts5,
  ContentTexts6,
  ContentTexts7,
  ContentTexts8,
  ContentTexts9,
  ContentTexts10,
};
