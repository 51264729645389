import { MotionButton } from '../MotionDiv';
import styles from './styles.module.css';

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    const allContentWrapper = document.getElementById('scrollableDiv');
    if (allContentWrapper) {
      allContentWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <MotionButton
      className={styles.ScrollUpButton}
      onClick={scrollToTop}
      whileHover={{ scale: 1.1 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <img
        src={'/assets/icons/arrow_right.svg'}
        alt={'scrollup'}
        width={24}
        height={24}
        style={{ transform: 'rotate(270deg)' }}
      />
    </MotionButton>
  );
};

export default ScrollToTopButton;
