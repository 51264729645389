import { useEffect, useRef, useState } from 'react';
import {
  ChooseVideoButton,
  Container,
  ContainerWrapper,
  ContentDescription,
  ContentTitle,
  LeftSide,
  RightSide,
  TitleContainer,
  TitleContentDiv,
  VideoBackground,
  VideoContentContainer,
  VideoDiv,
} from './styles';
import ScheduleDemoButton from '../../../../components/RequestDemoButton';

const DesktopVideoGallerySection = () => {
  const firstVideo = {
    id: 1,
    source: '/assets/video_player/videos/home.mp4',
    ref: useRef<HTMLVideoElement>(null),
    icon: '/assets/icons/arrow_right.svg',
    title: 'Start Experience | Home Page',
    description:
      'Home page designed to run in stand by mode, providing a video of the developers project that runs continuously, one click away from the start experience.',
  };

  const videos = [
    firstVideo,
    {
      id: 2,
      source: '/assets/video_player/videos/360.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/360.svg',
      title: '360 Degree Immersive Experience',
      description:
        'Step into a fully immersive experience with a seamless 360-degree view, offering unparalleled depth and interactivity at your fingertips.',
    },
    {
      id: 3,
      source: '/assets/video_player/videos/suntrack.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/timeline.svg',
      title: 'Timeline Day to Night',
      description:
        'Experience the passage of time firsthand with our dynamic day-to-night timeline feature, seamlessly transitioning through different lighting and conditions.',
    },
    {
      id: 4,
      source: '/assets/video_player/videos/virtual_tour.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/virtual_tour_icon.svg',
      title: 'Live | Virtual Tour',
      description:
        'Embark on a real-time exploration or virtual journey through our interactive tour, bridging the gap between physical presence and digital immersion effortlessly.',
    },
    {
      id: 5,
      source: '/assets/video_player/videos/floorplan.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/floorplan.svg',
      title: 'Floor Plan Gallery and Comparison',
      description:
        'Compare floor plans effortlessly with our interactive gallery, designed for easy side-by-side viewing and detailed analysis.',
    },
    {
      id: 6,
      source: '/assets/video_player/videos/gallery.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/gallery.svg',
      title: 'Gallery Images',
      description:
        'Explore our curated gallery of high-resolution images, showcasing every detail and perspective of the project.',
    },
    {
      id: 7,
      source: '/assets/video_player/videos/film.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/video.svg',
      title: 'Film Presentation',
      description:
        'Dive into our collection of engaging videos, offering an in-depth look at the project’s features and allowing you to see the project in more detail.',
    },
    {
      id: 8,
      source: '/assets/video_player/videos/map.mp4',
      ref: useRef<HTMLVideoElement>(null),
      icon: '/assets/icons/map.svg',
      title: 'Interactive Map',
      description:
        'Navigate through the project with our interactive map, featuring main points of interest in the surroundings and their distances.',
    },
  ];
  const [selectedVideo, setSelectedVideo] = useState(firstVideo);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            entry.target === selectedVideo.ref.current
          ) {
            selectedVideo.ref.current?.play().catch((error) => {
              console.log(error);
            });
          } else {
            selectedVideo.ref.current?.pause();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (selectedVideo.ref.current) {
      observer.observe(selectedVideo.ref.current);
    }

    return () => {
      if (selectedVideo.ref.current) {
        observer.unobserve(selectedVideo.ref.current);
      }
    };
  }, [selectedVideo]);

  useEffect(() => {
    const handleVideoEnded = () => {
      const currentIndex = videos.findIndex(
        (video) => video.id === selectedVideo.id
      );
      const nextIndex = (currentIndex + 1) % videos.length;
      setSelectedVideo(videos[nextIndex]);
    };

    const videoElement = selectedVideo.ref.current;
    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [selectedVideo]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    if (userInteracted && selectedVideo.ref.current) {
      selectedVideo.ref.current.volume = 1;
    }
  }, [userInteracted, selectedVideo]);

  return (
    <>
      <ContainerWrapper>
        <VideoBackground autoPlay muted loop>
          <source src='/assets/videos/hexagons.mp4' type='video/mp4' />
        </VideoBackground>
        <Container>
          <TitleContainer>
            <TitleContentDiv>
              <div>
                <ContentTitle>
                  Discover A New Way To Sell Properties
                </ContentTitle>
              </div>
              <div>
                <ContentDescription>
                  At Bees, we break the chains of convention, weaving a global
                  tapestry that unites property developers, agents and
                  homeowners under a singular banner: Forging the future of real
                  estate together through technology that everyone can use.
                </ContentDescription>
              </div>
              <div>
                <ScheduleDemoButton />
              </div>
            </TitleContentDiv>
          </TitleContainer>
          <VideoContentContainer id='feature_container'>
            <LeftSide>
              {videos.map((video) => (
                <ChooseVideoButton
                  key={video.id}
                  isSelected={video.id === selectedVideo.id}
                  onClick={() => {
                    if (video.id !== selectedVideo.id) {
                      setSelectedVideo(video);
                    }
                  }}
                >
                  <img src={video.icon} alt='video' />
                  <p>{video.title}</p>
                </ChooseVideoButton>
              ))}
            </LeftSide>
            <RightSide key={selectedVideo.id}>
              <VideoDiv>
                <div className='video-container'>
                  <video
                    ref={selectedVideo.ref}
                    src={selectedVideo.source}
                    controls
                    muted={!userInteracted}
                    playsInline
                    webkit-playsinline='true'
                  />
                </div>
              </VideoDiv>
              <p>{selectedVideo.description}</p>
            </RightSide>
          </VideoContentContainer>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default DesktopVideoGallerySection;
