import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #191c20;
  padding: 80px 0 60px 0;
`;

export const TitleDiv = styled.div`
  display: flex;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  max-width: 300px;

  h1 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
  }
`;
