import styles from './styles.module.css';
import BeesHeaderLogo from '../BeesHeaderLogo';
import { MotionButton, MotionDiv } from '../MotionDiv';
import UserProfile from '../UserProfile';
import {
  scrollToFeatures,
  scrollToScheduleDemo,
} from '../../functions/scrollToDiv';
import useWindowSize from '../../hooks/useWindowSize';

const Header = () => {
  const { width: screenWidth } = useWindowSize();

  return (
    <MotionDiv
      className={styles.Header}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0 }}
    >
      <BeesHeaderLogo />
      <div className={styles.HeaderRightSideDiv}>
        {screenWidth > 530 && (
          <div className={styles.ButtonsDiv}>
            <div>
              <MotionButton
                whileHover={{ scale: 1.04 }}
                transition={{ type: 'spring', stiffness: 300 }}
                onClick={scrollToFeatures}
              >
                Features
              </MotionButton>
            </div>
            <div style={{ borderRight: 'none' }}>
              <MotionButton
                whileHover={{ scale: 1.04 }}
                transition={{ type: 'spring', stiffness: 300 }}
                onClick={scrollToScheduleDemo}
              >
                Schedule a demo
              </MotionButton>
            </div>
          </div>
        )}

        <UserProfile />
      </div>
    </MotionDiv>
  );
};

export default Header;
