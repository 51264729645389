import React, { useState } from 'react';
import styles from './styles.module.css';
import CustomSelectInput from '../CustomSelectInput';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { Spinner } from '../../../../../../components/Spinner';

interface FormData {
  additional_message: string;
  name: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  heardAboutUs: string;
  contactMethodPhone: boolean;
  contactMethodEmail: boolean;
  agreeToTerms: boolean;
}

interface FormErrors {
  [key: string]: string | null;
}

const ScheduleDemoForm: React.FC = () => {
  const { width: screenWidth } = useWindowSize();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: '',
    heardAboutUs: '',
    additional_message: '',
    contactMethodPhone: false,
    contactMethodEmail: false,
    agreeToTerms: false,
  });
  const [hasContactMethodError, setHasContactMethodError] = useState(false);
  const [hasAgreeToTermsError, setHasAgreeToTermsError] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const validateField = (name: string, value: string) => {
    let error = null;

    if (name !== 'additional_message' && !value.trim()) {
      error = `${name.replace(/([A-Z])/g, ' $1')} is required`;
    } else if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Email address is invalid';
    }

    return error;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });

    setFormErrors({
      ...formErrors,
      [name]: validateField(name, String(newValue)),
    });
  };

  const handleSelectChange = (value: string) => {
    setFormErrors({
      ...formErrors,
      heardAboutUs: null,
    });
    setFormData({
      ...formData,
      heardAboutUs: value,
    });
  };

  const validate = () => {
    if (!formData.agreeToTerms) {
      setHasAgreeToTermsError(true);
    }

    if (!formData.contactMethodEmail && !formData.contactMethodPhone) {
      setHasContactMethodError(true);
    }

    const errors: FormErrors = {};
    Object.keys(formData).forEach((key) => {
      errors[key] = validateField(key, String(formData[key as keyof FormData]));
    });
    return errors;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      lastName: '',
      companyName: '',
      email: '',
      phone: '',
      heardAboutUs: '',
      additional_message: '',
      contactMethodPhone: false,
      contactMethodEmail: false,
      agreeToTerms: false,
    });

    setFormErrors({
      name: null,
      lastName: null,
      companyName: null,
      email: null,
      phone: null,
      heardAboutUs: null,
      additional_message: null,
      contactMethodPhone: null,
      contactMethodEmail: null,
      agreeToTerms: null,
    });
  };

  const handleSubmitRequest = async () => {
    setLoadingSubmit(true);
    try {
      const submitRequestFormData = new FormData();
      submitRequestFormData.append('type_page', 'send_email');
      submitRequestFormData.append('name', formData.name);
      submitRequestFormData.append('lastName', formData.lastName);
      submitRequestFormData.append('companyName', formData.companyName);
      submitRequestFormData.append('email', formData.email);
      submitRequestFormData.append('phone', formData.phone);
      submitRequestFormData.append('heardAboutUs', formData.heardAboutUs);
      submitRequestFormData.append(
        'contactMethodPhone',
        formData.contactMethodPhone ? 'true' : 'false'
      );
      submitRequestFormData.append(
        'contactMethodEmail',
        formData.contactMethodEmail ? 'true' : 'false'
      );
      submitRequestFormData.append(
        'agreeToTerms',
        formData.agreeToTerms ? 'true' : 'false'
      );
      submitRequestFormData.append(
        'additionalMessage',
        formData.additional_message
      );

      const response = await fetch('https://go-bees.com/send_email/index.php', {
        method: 'POST',
        body: submitRequestFormData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      resetForm();
    } catch (error) {
      console.log(error);
    }
    setLoadingSubmit(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('submit');
    e.preventDefault();
    const errors = validate();
    setFormErrors(errors);
    console.log('Errors:', errors);

    const hasErrors = Object.values(errors).some((error) => error !== null);
    if (
      !hasErrors &&
      formData.agreeToTerms &&
      (formData.contactMethodEmail || formData.contactMethodPhone)
    ) {
      console.log('Form data is valid, submitting:', formData);
      handleSubmitRequest();
    } else {
      console.log('Form data is invalid, not submitting');
    }
  };

  return (
    <form className={styles.FormDiv} onSubmit={handleSubmit}>
      <div className={styles.FormHeaderDiv}>
        <h2 className={styles.FormHeaderText}>SCHEDULE A DEMO NOW:</h2>
      </div>
      <div className={styles.FormInputsDiv}>
        {screenWidth > 700 ? (
          <div className={styles.NameInputsDiv}>
            <input
              type='text'
              name='name'
              placeholder='Name'
              className={`${styles.Input} ${
                formErrors.name ? styles.InputError : ''
              }`}
              value={formData.name}
              onChange={handleInputChange}
            />

            <input
              type='text'
              name='lastName'
              placeholder='Last Name'
              className={`${styles.Input} ${
                formErrors.lastName ? styles.InputError : ''
              }`}
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
        ) : (
          <>
            <input
              type='text'
              name='name'
              placeholder='Name'
              className={`${styles.Input} ${
                formErrors.name ? styles.InputError : ''
              }`}
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              type='text'
              name='lastName'
              placeholder='Last Name'
              className={`${styles.Input} ${
                formErrors.lastName ? styles.InputError : ''
              }`}
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </>
        )}
        <input
          type='text'
          name='companyName'
          placeholder="Company's Name"
          className={`${styles.Input} ${
            formErrors.companyName ? styles.InputError : ''
          }`}
          value={formData.companyName}
          onChange={handleInputChange}
        />
        <input
          type='email'
          name='email'
          placeholder='Email'
          className={`${styles.Input} ${
            formErrors.email ? styles.InputError : ''
          }`}
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          type='tel'
          name='phone'
          placeholder='Phone Number'
          className={`${styles.Input} ${
            formErrors.phone ? styles.InputError : ''
          }`}
          value={formData.phone}
          onChange={handleInputChange}
        />
        <div className={styles.SelectInputDiv}>
          <p
            className={styles.SelectInputText}
            style={{ color: formErrors.heardAboutUs ? '#ff8c8c' : '#ffffff' }}
          >
            HOW DID YOU HEAR ABOUT US?
          </p>
          <CustomSelectInput
            options={[
              { value: 'youtube', label: 'Youtube' },
              { value: 'instagram', label: 'Instagram' },
              { value: 'google', label: 'Google' },
              { value: 'other', label: 'Other' },
            ]}
            value={formData.heardAboutUs}
            onChange={handleSelectChange}
            hasError={!!formErrors.heardAboutUs}
          />
        </div>
        <input
          type='text'
          name='additional_message'
          placeholder='Additional Message'
          className={`${styles.MessageInput}`}
          value={formData.additional_message}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.SelectionInputsDiv}>
        <div className={styles.ContactsDiv}>
          <p className={styles.ContactTypeText}>
            *HOW WOULD YOU LIKE TO BE CONTACTED?
          </p>
          <div className={styles.ContactOptionsWrapper}>
            <div className={styles.ContactOptionDiv}>
              <input
                className={`${styles.CustomCheckbox} ${
                  hasContactMethodError ? styles.CustomCheckboxError : ''
                }`}
                type='checkbox'
                name='contactMethodPhone'
                checked={formData.contactMethodPhone}
                onChange={handleInputChange}
                onClick={() => {
                  setHasContactMethodError(false);
                }}
              />
              <p
                className={`${styles.ContactTypeText} ${
                  hasContactMethodError ? styles.ContactTypeTextError : ''
                }`}
              >
                PHONE
              </p>
            </div>
            <div className={styles.ContactOptionDiv}>
              <input
                className={`${styles.CustomCheckbox} ${
                  hasContactMethodError ? styles.CustomCheckboxError : ''
                }`}
                type='checkbox'
                name='contactMethodEmail'
                checked={formData.contactMethodEmail}
                onChange={handleInputChange}
                onClick={() => {
                  setHasContactMethodError(false);
                }}
              />
              <p
                className={`${styles.ContactTypeText} ${
                  hasContactMethodError ? styles.ContactTypeTextError : ''
                }`}
              >
                EMAIL
              </p>
            </div>
          </div>
        </div>

        <div className={styles.AgreementDiv}>
          <div>
            <input
              className={`${styles.CustomCheckbox} ${
                hasAgreeToTermsError ? styles.CustomCheckboxError : ''
              }`}
              type='checkbox'
              name='agreeToTerms'
              checked={formData.agreeToTerms}
              onChange={handleInputChange}
              onClick={() => {
                setHasAgreeToTermsError(false);
              }}
            />
          </div>
          <div>
            <p className={styles.TermsText}>
              Yes, I consent to receiving electronic messages, with updates on
              the project. If you wish to stop receiving this information at any
              time, simply check unsubscribe at the bottom of any email
              received.
            </p>
          </div>
        </div>
        <button className={styles.SubmitButton} type='submit'>
          {loadingSubmit ? (
            <Spinner />
          ) : (
            <div className={styles.SubmitButtonContentDiv}>
              <p className={styles.SubmitButtonText}>SUBMIT</p>
              <img
                src={'/assets/icons/submit_arrow_right.svg'}
                alt={'submit'}
                width={16}
                height={16}
              />
            </div>
          )}
        </button>
      </div>
    </form>
  );
};

export default ScheduleDemoForm;
