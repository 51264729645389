export const Spinner = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 50 50'
    xmlns='http://www.w3.org/2000/svg'
    fill='#fff'
  >
    <circle cx='25' cy='25' r='20' strokeWidth='5' stroke='#ccc' fill='none' />
    <circle
      cx='25'
      cy='25'
      r='20'
      strokeWidth='5'
      stroke='#333'
      fill='none'
      strokeDasharray='90'
      strokeDashoffset='0'
      strokeLinecap='round'
      transform='rotate(-90 25 25)'
      style={{ animation: 'spin 1s linear infinite' }}
    />
    <style>
      {`
        @keyframes spin {
          0% { stroke-dashoffset: 90; }
          100% { stroke-dashoffset: 0; }
        }
      `}
    </style>
  </svg>
);
